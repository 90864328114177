'use strict';

import $ from 'jquery'
import Snap from 'snapsvg'

$(function() {

  var defaultDurationTime = 500;
  var animatingSvg = Snap('#logo-neu');

  var circleAnimation = function(delay, element, duration, easing) {
    delay = delay || 0;
    duration = duration || defaultDurationTime;
    easing = easing || mina.backout;

    setTimeout(function(){
      element.animate({
        'r': element.attr('data-r')
      }, duration, easing);
    }, delay);
  };

  var lineVertAnimation = function(delay, element, duration, easing) {
    delay = delay || 0;
    duration = duration || defaultDurationTime;
    easing = easing || mina.backout;

    setTimeout(function(){
      element.animate({
        'y1': element.attr('data-y1'),
        'y2': element.attr('data-y2')
      }, duration, easing);
    }, delay);
  };

  var lineHorzAnimation = function(delay, element, duration, easing) {
    delay = delay || 0;
    duration = duration || defaultDurationTime;
    easing = easing || mina.backout;

    setTimeout(function(){
      element.animate({
        'x1': element.attr('data-x1'),
        'x2': element.attr('data-x2')
      }, duration, easing);
    }, delay);
  };

  /**
   * STROKE VERT 2
   */
  var lineVert_2_1 = animatingSvg.select('#line_vert_2-1'),
  circle_vert_2_6 = animatingSvg.select('#circle_vert_2-6'),
  circle_vert_2_5 = animatingSvg.select('#circle_vert_2-5'),
  circle_vert_2_4 = animatingSvg.select('#circle_vert_2-4'),
  circle_vert_2_3 = animatingSvg.select('#circle_vert_2-3'),
  circle_vert_2_2 = animatingSvg.select('#circle_vert_2-2'),
  circle_vert_2_1 = animatingSvg.select('#circle_vert_2-1'),
  outer_circle_vert_2_1 = animatingSvg.select('#outer_circle_vert_2-1'),
  outer_circle_vert_2_2 = animatingSvg.select('#outer_circle_vert_2-2'),
  outer_circle_vert_2_3 = animatingSvg.select('#outer_circle_vert_2-3');

  var startStrokeVert2 = function() {
    lineVertAnimation(0, lineVert_2_1, defaultDurationTime, mina.easeinout);

    setTimeout(function(){
      circleAnimation(0, circle_vert_2_6);
      circleAnimation(0, circle_vert_2_5);
      circleAnimation(0, circle_vert_2_4);
      circleAnimation(0, circle_vert_2_3);
      circleAnimation(0, circle_vert_2_2);
      circleAnimation(100, outer_circle_vert_2_1);
      circleAnimation(200, outer_circle_vert_2_2, 900);
      circleAnimation(300, outer_circle_vert_2_3, 600);
      circleAnimation(0, circle_vert_2_1);


    }, 350);
  };

  /**
   * STROKE HORZ 1
   */
  var line_horz_1_1 = animatingSvg.select('#line_horz_1-1'),
  circle_horz_1_4 = animatingSvg.select('#circle_horz_1-4'),
  circle_horz_1_3 = animatingSvg.select('#circle_horz_1-3'),
  circle_horz_1_2 = animatingSvg.select('#circle_horz_1-2'),
  circle_horz_1_1 = animatingSvg.select('#circle_horz_1-1'),
  outer_circle_horz_1_1 = animatingSvg.select('#outer_circle_horz_1-1'),
  outer_circle_horz_1_2 = animatingSvg.select('#outer_circle_horz_1-2');

  var startStrokeHorz1 = function() {
    lineHorzAnimation(0, line_horz_1_1, defaultDurationTime, mina.easeinout);

    setTimeout(function(){
      circleAnimation(0, circle_horz_1_4);
      circleAnimation(0, circle_horz_1_3);
      circleAnimation(0, circle_horz_1_2);
      circleAnimation(0, circle_horz_1_1);
      circleAnimation(300, outer_circle_horz_1_1, 700);
      circleAnimation(100, outer_circle_horz_1_2, 800);
    }, 400);

  };

  /**
   * STROKE VERT 1
   */
  var lineVert_1_1 = animatingSvg.select('#line_vert_1-1'),
  circle_vert_1_1 = animatingSvg.select('#circle_vert_1-1');

  var startStrokeVert1 = function() {
    lineVertAnimation(0, lineVert_1_1, defaultDurationTime, mina.easeinout);

    setTimeout(function(){
      circleAnimation(0, circle_vert_1_1);
    }, 350);
  };

  /**
   * STROKE HORZ 2
   */
  var line_horz_2_1 = animatingSvg.select('#line_horz_2-1'),
  circle_horz_2_1 = animatingSvg.select('#circle_horz_2-1'),
  circle_horz_2_2 = animatingSvg.select('#circle_horz_2-2'),
  circle_horz_2_3 = animatingSvg.select('#circle_horz_2-3'),
  outer_circle_horz_2_1 = animatingSvg.select('#outer_circle_horz_2-1');

  var startStrokeHorz2 = function() {
    lineHorzAnimation(0, line_horz_2_1, defaultDurationTime, mina.easeinout);

    setTimeout(function(){
      circleAnimation(0, circle_horz_2_1);
      circleAnimation(0, circle_horz_2_2);
      circleAnimation(0, circle_horz_2_3);
      circleAnimation(0, outer_circle_horz_2_1, 800);
    }, 500);

  };

  /**
   * STROKE HORZ 3
   */
  var line_horz_3_1 = animatingSvg.select('#line_horz_3-1'),
  circle_horz_3_1 = animatingSvg.select('#circle_horz_3-1'),
  circle_horz_3_2 = animatingSvg.select('#circle_horz_3-2'),
  circle_horz_3_3 = animatingSvg.select('#circle_horz_3-3'),
  outer_circle_horz_3_1 = animatingSvg.select('#outer_circle_horz_3-1'),
  outer_circle_horz_3_2 = animatingSvg.select('#outer_circle_horz_3-2');

  var startStrokeHorz3 = function() {
    lineHorzAnimation(0, line_horz_3_1, defaultDurationTime, mina.easeinout);

    setTimeout(function(){
      circleAnimation(0, circle_horz_3_1);
      circleAnimation(0, circle_horz_3_2);
      circleAnimation(0, circle_horz_3_3);
      circleAnimation(150, outer_circle_horz_3_1, 800);
      circleAnimation(100, outer_circle_horz_3_2, 800);
    }, 400);

  };

  /**
   * STROKE VERT 3
   */
  var lineVert_3_1 = animatingSvg.select('#line_vert_3-1'),
  circle_vert_3_1 = animatingSvg.select('#circle_vert_3-1'),
  outer_circle_vert_3_1 = animatingSvg.select('#outer_circle_vert_3-1');

  var startStrokeVert3 = function() {
    lineVertAnimation(0, lineVert_3_1, defaultDurationTime, mina.easeinout);

    setTimeout(function(){
      circleAnimation(0, circle_vert_3_1);
      circleAnimation(150, outer_circle_vert_3_1, 800);
    }, 350);
  };



  /**
   * LOGO "brainsome"
   */
  var LogoBrainsome = animatingSvg.select('#Logo');

  LogoBrainsome.attr({
    transform: "t-50,0"
  });

  var animateLogoBrainsome = function() {
    LogoBrainsome.animate({
      opacity: 1,
      transform: "t0,0"
    }, 550, mina.backout);
  };



  /**
   * Animate circles
   */
  var animateRandomCircle = function(circleSelector, radius, timeInterval) {
    var $animateCircle = $(''+circleSelector+''),
        randomNumberOfCircleLength = Math.floor((Math.random() * $animateCircle.length )),
        IdOfRandomCircle = $($animateCircle[randomNumberOfCircleLength]).attr('id'),
        RandomCircle = animatingSvg.select('#'+IdOfRandomCircle+'');

    var initialRadius = RandomCircle.attr('r'),
        increaseRadius = initialRadius * radius;

    RandomCircle.animate({
      'r': increaseRadius
    }, 200, function(){
      RandomCircle.animate({
        'r': initialRadius
      }, 1250, mina.elastic, function () {
        setTimeout(animateRandomCircle(circleSelector, radius, Math.random()+1), (Math.random()+timeInterval)*250);
      })
    });
  };


  setTimeout(function(){
    startStrokeVert2();
  }, 200);

  setTimeout(function(){
    startStrokeHorz1();
  }, 700);

  setTimeout(function(){
    startStrokeVert1();
  }, 900);

  setTimeout(function(){
    startStrokeHorz2();
  }, 1000);

  setTimeout(function(){
    startStrokeHorz3();
  }, 800);

  setTimeout(function(){
    startStrokeVert3();
  }, 1200);

  setTimeout(function(){
    animatingSvg.removeClass('startgrey');
  }, 1300);

  setTimeout(function(){
    animateLogoBrainsome();
  }, 1400);

  setTimeout(function() {
    animateRandomCircle('.animate-outer-circle', 1.1, 1.1);
  }, 2200);

  setTimeout(function () {
    animateRandomCircle('.animate-inner-circle', 1.25, 1.5);
  }, 2600);



});
